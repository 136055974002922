<template>
  <div
    class="absolute h-screen w-screen bg-black/30 transition-all duration-300"
    :class="{
      'pointer-events-none select-none bg-black/0 opacity-0 ':
        !open || !dropdown?.children?.length > 0,
    }"
  >
    <Container
      class="perspective-2000"
      :class="`${dropdown?.page?.color_theme?.value || 'blue'}-el`"
    >
      <div
        class="mt-4 min-h-[15rem] origin-top rounded-lg bg-white px-12 py-14 lg:px-24 lg:py-16"
        :class="{ 'hide-dropdown': !open || !dropdown?.children?.length > 0 }"
        @mouseleave="$emit('close-dropdown')"
      >
        <ul
          id="dropdown"
          class="-ml-12 grid h-min grid-cols-2 gap-y-12 divide-x-[1.5px] divide-stone-200/80 transition-all lg:grid-cols-3 max-[1024px]:[&>li:nth-child(2n-1)]:border-none lg:[&>li:nth-child(3n-2)]:border-none"
        >
          <li
            v-for="(level2, indexLevel2) in dropdown.children"
            :key="indexLevel2"
            class="pl-12 text-2xs font-semibold uppercase"
          >
            <!-- Level 2 -->
            <NuxtLink
              v-if="level2.page.url"
              :to="checkBlueprintOfNavItem(level2)"
              class="focus-default text-theme-hover -mt-3 -ml-2 mr-1 inline-block w-fit px-2 hover:underline focus-visible:rounded-[1.5em]"
              @keyup.esc="$emit('close-dropdown')"
            >
              {{ level2.page.title }}
            </NuxtLink>
            <span v-else class="-mt-3">
              {{ level2.page.title }}
            </span>
            <!-- END Level 2 -->

            <!-- Level 3 -->
            <ul class="mt-4">
              <li
                v-for="(level3, indexLevel3) in level2.children"
                :key="indexLevel3"
                class=""
              >
                <NuxtLink
                  :to="level3.page.url"
                  class="focus-default text-theme-hover -m-2 inline-block p-2 text-base font-normal normal-case hover:underline"
                  @keyup.esc="$emit('close-dropdown')"
                >
                  {{ level3.page.title }}
                </NuxtLink>
              </li>
            </ul>
            <!-- END Level 3 -->
          </li>
        </ul>
        <button
          :aria-label="
            open
              ? 'Schliesse Navigations Dropdown'
              : 'Öffne Navigations Dropdown'
          "
          :tabindex="open ? '' : '-1'"
          @focus="$emit('close-dropdown')"
        />
      </div>
    </Container>
  </div>
</template>

<script setup>
const props = defineProps({
  dropdown: { type: Object, required: true },
  open: { type: Boolean, required: true },
  usesfocus: { type: Boolean, default: false },
})
defineEmits(['close-dropdown'])

watch(
  () => props.open,
  () => {
    if (props.open && props.usesfocus) {
      document.getElementById('dropdown').getElementsByTagName('a')[0]?.focus()
    } else if (props.usesfocus) {
      document?.activeElement.blur()
      document.getElementById('dropdown')?.blur()
      document.getElementById('jump-navigation')?.focus()
    }
  }
)

// if the nav item is a content page, return the url
const checkBlueprintOfNavItem = (navItem) => {
  navItem.page.url

  if (navItem.page.blueprint === 'content_page') {
    return navItem.page.url
  }

  // otherwise take the first child if exists
  if (navItem.children.length > 0) {
    return navItem.children[0]?.page.url
  }
}
</script>

<style lang="scss" scoped>
.perspective-2000 {
  perspective: 2000px;
}
.hide-dropdown {
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transform: rotateX(-60deg);
  transition: 200ms;
  opacity: 0;
  @apply pointer-events-none;
}

.router-link-active,
.router-link-exact-active {
  @apply text-blue-500 pink-el:text-pink-500 green-el:text-green-500;
}
.select-none a {
  user-select: none;
}
</style>
